import $ from 'jquery';

export default function googleMaps (vars){

    var $map = $('.js-map');
    var map;

    if ($map.length) {

        google.maps.event.addDomListener(window, 'load', init);

        var defaultOptions = {
                center: 'new google.maps.LatLng(51.457618,-2.594047)',
                zoom: 14,
                zoomControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.DEFAULT,
                },
                disableDoubleClickZoom: true,
                mapTypeControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                },
                scaleControl: false,
                scrollwheel: false,
                panControl: false,
                streetViewControl: true,
                draggable : false,
                overviewMapControl: false,
                overviewMapControlOptions: {
                    opened: false,
                },
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [{"featureType":"administrative.land_parcel","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape.man_made","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"simplified"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"hue":"#f49935"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"hue":"#fad959"}]},{"featureType":"road.arterial","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"road.local","elementType":"labels","stylers":[{"visibility":"simplified"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"hue":"#a1cdfc"},{"saturation":30},{"lightness":49}]}],
        };
        function init() {
            $( $map ).each( function( index, element ){
                var mapOptions = $.extend({}, defaultOptions);
                    mapOptions.center = new google.maps.LatLng($(this).data('lat'), $(this).data('lng'));
                var map = new google.maps.Map(element, mapOptions);
                var marker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng($(this).data('lat'), $(this).data('lng')),
                    icon: '/assets/img/map-marker.png',
                    title: $(this).data('map-title')
                });
            });
        }
    }
}